import React from 'react'
import PropTypes from 'prop-types'
import IndexPageTemplate from './index-page'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'

const IndexPageEN = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout
      lang="en"
      navbarTitle={frontmatter.title}
      navbarLinks={frontmatter.navbar}
    >
      <IndexPageTemplate
        lang="en"
        mainImage={frontmatter.mainImage}
        title={frontmatter.title}
        mainPitch={frontmatter.mainPitch}
        albums={frontmatter.albums}
        contactForm={frontmatter.contactForm}
      />
    </Layout>
  )
}

IndexPageEN.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
}

export default IndexPageEN

export const pageQuery = graphql`
  query IndexPageTemplateEN {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page-en" } }) {
      frontmatter {
        title
        navbar {
          text
          anchor
        }
        mainImage {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 95) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        mainPitch {
          title
          description
          sideImage {
            image {
              childImageSharp {
                fluid(maxWidth: 720, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            alt
          }
        }
        contactForm {
          labelName
          labelEmail
          labelSubject
          labelMessage
          labelSend
        }
        albums {
          title
          spotify
          apple_music
          cover {
            childImageSharp {
              fluid(maxWidth: 500, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
